<script>
import { authComputed } from '@state/helpers'

export default {
    data() {
        return {}
    },
    computed: {
        ...authComputed,
        // languageLabel() {
        //     return this.getI18nModified({
        //         suffix: 'language',
        //         prefix: 'LANGUAGES',
        //         modifier: 1,
        //     })
        // },
        // profileLabel() {
        //     return this.getI18nModified({
        //         suffix: 'TITLES.profile',
        //         prefix: 'PROFILE',
        //         modifier: 2,
        //     })
        // },
        i18nEditProfile() {
            return this.getI18n('USERS', 'BUTTONS.edit_profile')
        },
        i18nNotification() {
            return this.getI18nModified({
                suffix: 'notification',
                prefix: 'COMMON',
                modifier: 2,
            })
        },
        i18nAbout() {
            return this.getI18n('COMMON', 'about')
        },
        i18nHelp() {
            return this.getI18n('COMMON', 'help')
        },
        i18nContact() {
            return this.getI18n('COMMON', 'contact')
        },
        i18nLogout() {
            return this.getI18n('COMMON', 'logout')
        },
        userName() {
            return this.$store.state.auth.currentUser
                ? this.$store.state.auth.currentUser.name
                : ''
        },
        // profilePictureUrl() {
        //     return this.$store.state.auth.currentUser
        //         ? this.$store.state.auth.currentUser.profile_picture_url
        //         : ''
        // },
        // hasPicture() {
        //     return this.profilePictureUrl !== ''
        // },
    },
    methods: {
        toggleMenu() {
            this.$parent.toggleMenu()
        },
    },
}
</script>

<template>
    <!-- Topbar Start -->
    <div class="navbar-custom">
        <div class="d-flex">
            <div v-if="!isSuperAdmin" class="logo-box">
                <a :href="$can('GET', 'DASHBOARDRESUME') ? '/' : '/dashboard/photoGallery'" class="logo text-center">
                    <span class="logo-lg">
                        <img src="@assets/images/logo-light.png" alt="logo-light" />
                    </span>
                    <span class="logo-sm">
                        <img src="@assets/images/logo-sm.png" alt="logo-light-sm" />
                    </span>
                </a>
            </div>

            <ul v-if="isSuperAdmin" class="list-unstyled topnav-menu topnav-menu-left m-0">
                <div class="ml-3">
                    <a href="/tenant" class="logo text-center">
                        <span class="logo-lg">
                            <img src="@assets/images/logo-dark.png" alt="logo-dark" />
                        </span>
                    </a>
                </div>
            </ul>
            <ul v-else class="list-unstyled topnav-menu topnav-menu-left m-0">
                <li>
                    <button class="button-menu-mobile" @click="toggleMenu">
                        <i class="fe-menu"></i>
                    </button>
                </li>

                <li class="d-none d-md-flex">
                    <div class="d-flex flex-column">
                        <span>{{ userName }}</span>
                        <router-link to="/profile">{{ i18nEditProfile }}</router-link>
                    </div>
                </li>
            </ul>

            <ul class="list-unstyled topnav-menu topnav-menu-right mb-0">
                <li v-if="isSuperAdmin" class="d-none d-md-flex">
                    <img
                        src="@assets/images/logo-sm-dark.png"
                        alt="admin-user-image"
                    />
                    <div class="d-flex align-items-center">
                        <span>{{ userName }}</span>
                    </div>
                </li>
                <li>
                    <router-link to="/logout">
                        <i class="fe-log-out"></i>
                        <span>{{ i18nLogout }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
    <!-- end Topbar -->
</template>

<style lang="scss">
.noti-scroll {
    height: 220px;
}

.ps > .ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    width: 6px !important;
}

.button-menu-mobile {
    outline: none !important;
}
</style>
