import BaseService from '@src/services/BaseService'

class UsersService extends BaseService {
    constructor() {
        super('users')
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }

    fetchSidenavFavorites(id) {
        return this.axios.post(`${this.getUrl()}/${id}/sidenav/favorites`)
    }

    updateSidenavFavorites(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/sidenav/favorites/update`, params)
    }
}

export default new UsersService()
